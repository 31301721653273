<template>
  <div
    :class="['item__pr', 'col-lg-4', gridView ? 'col-6' : 'col-12']"
    @click="$emit('close-popup')"
  >
    <a class="item_product_page" @click="openDishPopup">
      <div class="img_favorits">
        <div v-if="dish.stock === 0" class="card_splash">
          {{ $t("out_of_stock") }}
        </div>
        <img :src="'/storage/' + dish.image_menu" alt="" class="mw-100" />
      </div>
      <div class="information_product">
        <p class="name_product">
          {{ dish.name }}
        </p>
        <p class="desc__product" v-html="dish.description"></p>
        <p class="price__product">{{ dish.price_sell }}€</p>
      </div>
    </a>

    <IncompatibleModesPopup
      v-if="incompatible"
      @clear-cart-and-let-in="clearAndOpenDishPopup"
      @close-popups="closeIncompatibleModesPopups"
    />
    <DishPopup
      v-if="showPopup && !incompatible"
      :dish="dish"
      @close-popup="closeDishPopup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DishPopup from "@/components/DishPopup";
import IncompatibleModesPopup from "@/components/IncompatibleModesPopup";

export default {
  props: ["dish", "gridView"],

  components: {
    DishPopup,
    IncompatibleModesPopup
  },

  data() {
    return {
      incompatible: false,
      showPopup: false
    };
  },

  computed: {
    ...mapGetters("cart", ["isPickup"]),
    ...mapState("cart", ["cart"])
  },

  methods: {
    ...mapActions("auth", ["me"]),
    ...mapActions("cart", ["deleteCart"]),
    openDishPopup() {
      if (this.dish.stock === 0) return;
      if (
        this.cart.is_pickup &&
        this.cart.restaurants.length > 0 &&
        !this.cart.restaurants.includes(this.dish.restaurant)
      ) {
        this.incompatible = true;
      }

      this.showPopup = true;
    },
    closeDishPopup() {
      this.showPopup = false;
    },
    async clearAndOpenDishPopup() {
      await this.deleteCart();
      this.incompatible = false;
    },
    closeIncompatibleModesPopups() {
      this.incompatible = false;
      this.closeDishPopup();
    }
  }
};
</script>
