<template>
  <div id="modal__sorry" class="modal_home">
    <div class="content_modal_home">
      <button class="close__modal" @click="$emit('clear-errors')"></button>
      <div class="warring_text">
        <span>{{ $t("attention") }}</span
        ><br />
        <template v-for="(error, field) in errors.errors">
          {{ error[0] }}<br :key="field" />
        </template>
      </div>
      <div v-if="isHardError" class="btn_block__modal mb-0">
        <button class="nav__btn" @click="$emit('clear-cart-and-errors')">
          {{ $t("dish.erase_cart") }}
        </button>
        <button class="nav__btn active" @click="$emit('close-all')">
          {{ $t("dish.back_to_restaurant") }}
        </button>
      </div>
      <div v-else class="btn_block__modal mb-0">
        <button class="nav__btn" @click="$emit('clear-errors')">
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["errors"],

  computed: {
    isHardError() {
      return Object.prototype.hasOwnProperty.call(this.errors.errors, "dish");
    }
  }
};
</script>
