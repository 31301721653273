<template>
  <div>
    <CartItemErrorPopup
      v-if="Object.entries(errors).length"
      :errors="errors"
      @clear-errors="errors = []"
      @clear-cart-and-errors="eraseCart"
      @close-all="$emit('close-popup')"
    />
    <div v-if="showError" id="modal__sorry" class="modal_home">
      <div class="content_modal_home">
        <button class="close__modal" @click="showError = false"></button>
        <div class="warring_text">
          {{ $t("need_authorize") }}
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="showError = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <div v-show="errors.length < 1" id="modal_rest" class="modal_rest">
      <div class="content_rest">
        <button class="close_big" @click="$emit('close-popup')"></button>
        <div class="img_rest__modal">
          <img :src="'/storage/' + dish.image_popup" alt="" class="mw-100" />
          <button
            :class="['favorits_btn__modal', { active: isFavourite }]"
            @click.stop.prevent="toggleFavourite"
          ></button>
        </div>
        <div class="desc_block__modal">
          <h3 class="name__prod__modal">
            {{ dish.name }}
            <span class="price__modal"> {{ dish.price_sell }}€ </span>
          </h3>
          <p class="desc__prod__modal" v-html="dish.description"></p>
          <!-- MODIFICATIONS -->
          <div v-if="dish.modifications.length > 0" class="radio_modal">
            <h4 class="title_form">{{ dish.modifications_title }}</h4>

            <template v-for="item in dish.modifications">
              <input
                  :id="'modifications-' + item.id"
                  :key="'modifications-' + item.id"
                  v-model="form.modification"
                  :value="item.id"
                  class="radio__modal_style"
                  type="radio"
              />
              <label
                  :key="'modifications-' + item.id"
                  :for="'modifications-' + item.id"
              >
                {{ item.name }}
                {{ item.price_sell > 0 ? "+" + item.price_sell + "€" : "" }}
              </label>
            </template>
          </div>
          <!-- TOPPINGS -->
          <div v-if="dish.toppings.length > 0" class="checkbox_modal">
            <h4 class="title_form">{{ dish.toppings_title }}</h4>

            <template v-for="item in dish.toppings">
              <input
                :id="'toppings-' + item.id"
                :key="'toppings-' + item.id"
                v-model="form.toppings"
                :value="item.id"
                class="checbox__modal_style"
                type="checkbox"
              />
              <label :key="'toppings-' + item.id" :for="'toppings-' + item.id">
                {{ item.name }}
                {{ item.price_sell > 0 ? "+" + item.price_sell + "€" : "" }}
              </label>
            </template>
          </div>

          <!-- TOPPINGS ADDIT -->
          <div v-if="dish.toppings_addit.length > 0" class="checkbox_modal">
            <h4 class="title_form">{{ dish.toppings_addit_title }}</h4>

            <template v-for="item in dish.toppings_addit">
              <input
                :id="'toppings_addit-' + item.id"
                :key="'toppings_addit-' + item.id"
                v-model="form.toppings_addit"
                :value="item.id"
                class="checbox__modal_style"
                type="checkbox"
              />
              <label
                :key="'toppings_addit-' + item.id"
                :for="'toppings_addit-' + item.id"
              >
                {{ item.name }}
                {{ item.price_sell > 0 ? "+" + item.price_sell + "€" : "" }}
              </label>
            </template>
          </div>

          <!-- ACCOMPANIMENTS -->
          <div v-if="dish.accompaniments.length > 0" class="radio_modal">
            <h4 class="title_form">{{ dish.accompaniments_title }}</h4>

            <template v-for="item in dish.accompaniments">
              <input
                :id="'accompaniments-' + item.id"
                :key="'accompaniments-' + item.id"
                v-model="form.accompaniment"
                :value="item.id"
                class="radio__modal_style"
                type="radio"
              />
              <label
                :key="'accompaniments-' + item.id"
                :for="'accompaniments-' + item.id"
              >
                {{ item.name }}
                {{ item.price_sell > 0 ? "+" + item.price_sell + "€" : "" }}
              </label>
            </template>
          </div>

          <textarea
            v-model="form.comment"
            class="textarea_modal"
            :placeholder="$t('dish.comments')"
          ></textarea>

          <div class="coll_product__modal">
            <button class="minus_modal" @click="decrementQuantity">
              <span>-</span>
            </button>
            <input
              :value="form.quantity"
              class="coll_input__modal"
              type="text"
            />
            <button class="plus_modal" @click="incrementQuantity">
              <span>+</span>
            </button>
          </div>
          <div class="error_text" v-if="form.quantity == dish.stock" >
            {{ $t("cart.max_amount") }} : {{ dish.stock }}
          </div>
          <button class="cart__btn" @click="addToCart">
            {{ $t("dish.add_to_cart") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CartItemErrorPopup from "@/components/CartItemErrorPopup";

export default {
  props: ["dish"],

  components: {
    CartItemErrorPopup
  },

  data() {
    return {
      errors: [],
      error: "",
      showError: false,
      form: {
        dish: null,
        quantity: 1,
        comment: "",
        toppings: [],
        toppings_addit: [],
        period: this.$route.query.period || this.dish.primary_period
      }
    };
  },
  mounted() {
    this.form.dish = this.dish.id;
  },
  computed: {
    ...mapState("auth", ["user"]),
    isHardError() {
      return Object.prototype.hasOwnProperty.call(this.errors.errors, "dish");
    },
    isFavourite() {
      if (this.user !== null)
        return this.user.favourite_dishes.some(
          fav => fav.slug === this.dish.slug
        );
      else return false;
    }
  },

  methods: {
    ...mapActions("cart", ["addItem", "deleteCart"]),
    ...mapActions("auth", ["me"]),
    toggleFavourite() {
      this.$http
        .post("/api/user/favourites", {
          resource: "dish",
          resource_id: this.dish.slug
        })
        .then(() => {
          this.me();
          this.showError = false;
        })
        .catch(error => {
          this.showError = true;
          this.error = error.response.message;
        });
    },
    decrementQuantity() {
      if (!this.form.dish) return;
      if (this.form.quantity - 1 < 1) return;

      this.form.quantity--;
    },
    incrementQuantity() {
      if (!this.form.dish) return;
      if (this.form.quantity + 1 > this.dish.per_order) return;
      if (this.form.quantity + 1 > this.dish.stock) {

        return;
      }

      this.form.quantity++;
    },
    eraseCart() {
      this.deleteCart().then(() => {
        this.errors = [];
      });
    },
    addToCart() {
      if (!this.form.dish) return;

      this.addItem(this.form)
        .then(() => {
          this.$emit("close-popup");
        })
        .catch(error => (this.errors = error.response.data));
    }
  }
};
</script>
