<template>
  <div>
    <section id="restouran_slide">
      <Swiper
        v-if="restaurant.sliders.length > 1"
        :options="restaurantSwiperOptions"
      >
        <SwiperSlide
          v-for="slider in restaurant.sliders"
          :key="slider.id"
          :style="{
            'background-image': `url(/storage/${slider.image_desktop})`
          }"
          class="restaurant_slider_image"
        />
      </Swiper>
      <Swiper v-else :options="restaurantSwiperOptions">
        <SwiperSlide
          :style="{
            'background-image': `url(/storage/${restaurant.image_big})`
          }"
          class="restaurant_slider_image"
        ></SwiperSlide>
      </Swiper>

      <Swiper
        v-if="restaurant.sliders.length > 1"
        :options="restaurantSwiperOptions"
      >
        <SwiperSlide
          v-for="slider in restaurant.sliders"
          :key="slider.id"
          :style="{
            'background-image': `url(/storage/${slider.image_mobile})`
          }"
          class="restaurant_slider_image restaurant_slider_image--mobile"
        />
      </Swiper>
      <Swiper v-else :options="restaurantSwiperOptions">
        <SwiperSlide
          :style="{
            'background-image': `url(/storage/${restaurant.image_big})`
          }"
          class="restaurant_slider_image restaurant_slider_image--mobile"
        ></SwiperSlide>
      </Swiper>

      <div class="container restaurant_slide_info">
        <h1 class="title__home">
          {{ restaurant.name }}
        </h1>
        <p class="time_work__slide">
          <template>
            <template v-if="getClosestOrderTime(restaurant)">
              {{ $t("earliest_delivery") }}
              <span>{{ getClosestOrderTime(restaurant) }}</span>
            </template>
            <template v-else>
              {{ $t("pre_order_for_tomorrow") }}
            </template>
          </template>
        </p>
      </div>
    </section>
    <div v-if="restaurant.splash" class="info__restouran_block">
      <span>INFOS</span><br />
      {{ restaurant.splash.content }}
    </div>
    <div>
      <div class="tabs_block">
        <a
          v-for="(label, id) in periods"
          :key="id"
          :class="['items__tabs', { active: $route.query.period === id }]"
          @click="period = id"
        >
          {{ label }}
        </a>
      </div>
    </div>
    <section class="category_block__slide">
      <div class="pt-0">
        <div class="category_slide">
          <Swiper
            v-show="restaurant.sections.length > 0"
            ref="sectionsSwiper"
            :options="sectionsSwiperOptions"
            class="swiper_restaurant_sections"
          >
            <SwiperSlide
              v-for="section in restaurant.sections"
              :key="section.id"
            >
              <router-link
                :to="'#' + section.slug"
                active-class="active"
                class="item_category__slilde"
                tag="h2"
                @click.native="scrollToId(section.slug)"
              >
                <a class="item_shop">
                  {{ section.name }}
                </a>
              </router-link>
            </SwiperSlide>
            <div slot="button-prev" class="prev_btn">&lt;</div>
            <div slot="button-next" class="next_btn">&gt;</div>
          </Swiper>
        </div>
      </div>
    </section>
    <section id="restouran__pdf" v-if="restaurant.is_allergen">
      <a :href="'/storage/' + restaurant.pdf_file" target="_blank">
        <button class="list_alerg">{{ $t("list_alerg") }}&nbsp;→</button>
      </a>
    </section>
    <section id="restouran__page">
      <div class="container">
        <div class="mobail__tabs">
          <button
            id="mini_prod"
            :class="['mini_prod', { active: gridView === true }]"
            @click="gridView = true"
          ></button>
          <img alt="" src="/img/line.svg" />
          <button
            id="full_prod"
            :class="['full_prod', { active: gridView === false }]"
            @click="gridView = false"
          ></button>
        </div>
        <template v-for="(section, index) in restaurant.sections">
          <h3
            :id="section.slug"
            :key="section.id"
            :data-index="index"
            class="title_page__style observable"
          >
            {{ section.name }}
          </h3>
          <div :key="section.id" class="row">
            <template v-for="dish in section.dishes">
              <DishCard :key="dish.id" :dish="dish" :grid-view="gridView" />
            </template>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import DishCard from "@/components/DishCard";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
    DishCard
  },

  data() {
    return {
      gridView: true,
      searchQuery: "",
      period: null,
      periods: [],
      restaurant: {
        is_allergen: false,
        pdf_file: "",
        sections: [],
        sliders: []
      },
      filters: [],
      restaurantSwiperOptions: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        }
      },
      sectionsSwiperOptions: {
        slidesPerView: "auto",
        navigation: {
          nextEl: ".next_btn",
          prevEl: ".prev_btn"
        }
      },
      observer: null
    };
  },

  computed: {
    ...mapGetters("cart", ["isPickup"]),
    ...mapState("cart", ["cart"]),
    sectionsSwiper() {
      return this.$refs.sectionsSwiper.$swiper;
    }
  },

  watch: {
    period(new_value, old_val) {
      if (this.$route.query.period === undefined || old_val !== null) {
        this.$router.replace({ query: { period: new_value } });
      }
      this.getData();
    }
  },

  created() {
    this.$http
      .get(`/api/restaurants/${this.$route.params.restaurant}/periods`)
      .then(response => {
        this.periods = Object.assign(
          {},
          ...response.data.data.map(period => ({
            [period.id]: period.name
          }))
        );
        this.period = this.$route.query.period || response.data.meta.primary.id;
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.$router.replace({ name: "home" });
        }
      });
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {
    getData(params = {}) {
      params.period = this.period;
      this.$http
        .get(`/api/restaurants/${this.$route.params.restaurant}`, { params })
        .then(response => {
          this.restaurant = response.data.data;
          this.restaurant.is_allergen = response.data.data.is_allergen;
          this.restaurant.pdf_file = response.data.data.pdf_file;
          this.filters = this.cuisines = response.data.meta.filters;
          this.$nextTick(() => {
            if (this.$route.hash) {
              this.scrollToId(this.$route.hash.substr(1));
            }
            document
              .querySelectorAll(".observable")
              .forEach(el => this.observer.observe(el));
          });
        });
      this.observer = new IntersectionObserver(this.onElementObserved, {
        root: null,
        rootMargin: "0px 0px -100% 0px",
        threshold: 0
      });
    },
    onElementObserved(entries) {
      if (entries.length === this.restaurant.sections.length) return;

      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) return;

        const hash = "#" + target.getAttribute("id");
        if (this.$route.hash !== hash) {
          this.$router.replace({ hash, query: { period: this.period } });
          this.sectionsSwiper.slideTo(target.getAttribute("data-index"));
        }
      });
    },
    scrollToId(id) {
      document.getElementById(id).scrollIntoView();
      window.scrollBy(0, -150);
    },
    getClosestOrderTime(restaurant) {
      return this.isPickup
        ? restaurant.closest_order_time_pickup
        : restaurant.closest_order_time_delivery;
    }
  },

  metaInfo() {
    return {
      title: this.restaurant.seo_title,
      meta: [
        {
          name: "description",
          content: this.restaurant.seo_alt
        },
        {
          name: "keywords",
          content: this.restaurant.seo_keywords
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~swiper/css/swiper.css";

.swiper_restaurant_sections {
  height: 100%;

  .swiper-slide {
    max-width: fit-content;

    a {
      color: #707070;
    }
  }
}

#restouran__pdf{
  max-width: 1036px;
  margin: auto;
}
.list_alerg {
  padding: 15px;
  width: auto;
  margin-top: 40px;
  font-family: Roboto;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #ea564f;
  color: #fff;
  transition: all 0.5s;
  position: relative;
}
@media screen and (max-width: 500px) {
  .list_alerg {
    padding: 15px;
    width: auto;
    margin-top: 20px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #ea564f;
    color: #fff;
    transition: all 0.5s;
    position: absolute;
  }
}
</style>
